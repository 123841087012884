<template>
  <div class="g_page_box qrcode">
    <div class="recommend">推广码：{{id}}</div>
    <div class="img_box">
      <img src="https://open.weixin.qq.com/qr/code?username=szluohuhospital" class="img">
      <div class="text">长按关注罗湖人民医院公众号</div>
    </div>
  </div>
</template>
<script>
import {weixin_login, add_recommend} from './service'
import {get_url_params} from '../../utils/format'

export default {
  data () {
    return {
      id: this.$route.query.id
    }
  },
  computed: {
  },
  components: {
  },
  watch: {
  },
  created () {
    // 控制退出公众号
    window.history.pushState(null, null)
    window.addEventListener('popstate', this.popstate, false)

    this.init_data()
  },
  destroyed () {
    window.removeEventListener('popstate', this.popstate, false)
  },
  methods: {
    popstate (e) {
      //qiankun 微服务内部api，更新可能引起未知bug
      if(e.singleSpaTrigger === "pushState" || e.singleSpaTrigger === "replaceState"){
        return
      }
      wx.closeWindow() // 微信网页退出
    },
    async init_data () {
      let {code} = get_url_params(location.href.split('#')[0])

      if (!code) {
        let url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${GLOBAL_CONFIG.app_id}&redirect_uri=${encodeURIComponent(location.href)}&response_type=code&scope=snsapi_base&state=123#wechat_redirect`;
        location.href = url;
        throw '暂停执行'; // 退出后面的执行
      }

      const {data} = await weixin_login({code})
      add_recommend({
        promotion_id: this.id,
        open_id: data.openid,
      })

    }
  }
}
</script>
<style scoped>
.qrcode {
  background: #434343;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.recommend {
  position: absolute;
  top: 20px;
  left: 15px;
  height: 15px;
  color: #999;
}
.img_box {
  width: 200px;
  text-align: center;
  & .img {
    width: 100%;
  }
  & .text {
    color: #fff;
    margin-top: 15px;
  }
}
</style>
