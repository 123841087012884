import api from '../../utils/api'


// 获取微信用户信息
export async function weixin_login (payload) {
  return api.get(`/api/v1/mp/luohu/users/info`, {params: payload})
}

// 推荐记录
export async function add_recommend (payload) {
  return api.post(`/api/v1/operation/promotion_qr/record/add`, payload)
}
